import * as animations from "../Animations";

export const DefaultTransition = {
  name: 'default-transition',
  sync: false,
  debug: false,
  defaultAnimation: 'FadeAnimation',
  once: function (data) {
    setTimeout(() => {
      $('.transitionning').removeClass('transitionning');
      this.enter(data);
    }, 500);
  },
  leave: function (transitionData) {
    const $oldContainer = $(transitionData.current.container);

    return new Promise((resolve, reject) => {
      const linkAnimation = transitionData && transitionData.trigger && $(transitionData.trigger) && $(transitionData.trigger).data('animation-out');
      const bpAnimation = (transitionData && transitionData.trigger && $(transitionData.trigger) && $(transitionData.trigger).parents('.section-sommaire').length) ? 'HomeDefaultAnimation' : null;
      const pageAnimation = $oldContainer.data('animation-out')
      let animationName = linkAnimation || bpAnimation || pageAnimation || this.defaultAnimation;
      //console.log(linkAnimation, bpAnimation, pageAnimation, this.defaultAnimation, animationName);
      const animation = this.locateAnimation(animationName);
      if (!animation) {
        reject("Leave animation not found");
      }

      this.log('[Transition Leave Start]', animation);
      animation.getLeaveAnimation($oldContainer, {
        onStart: () => {
          $('body').addClass('animating');
        },
        onComplete: () => {
          this.log('[Transition Leave Completed]');
          resolve();
        }
      }, transitionData);

    });
  },
  enter: function (transitionData) {
    const $oldContainer = $(transitionData.current.container);
    $oldContainer.remove();
    const $newContainer = $(transitionData.next.container);

    return new Promise((resolve, reject) => {
      let animationName = $newContainer.data('animation-in');
      const animation = this.locateAnimation(animationName);
      if (!animation) {
        reject("Enter animation not found");
      }

      this.log('[Transition Enter Start]', animation);

      animation.getEnterAnimation($newContainer, {
        onComplete: () => {
          $('body').removeClass('animating');
          this.log('[Transition Enter Completed]');
          resolve();
        }
      }, transitionData);

    });

    /*const enterTransition = gsap.timeline({
      onComplete: () => {
        console.log('[Transition Enter Completed]');
        $entryContent.addClass('transition-completed').css('transform', 'none');
      }
    });

    enterTransition.from($newContainer, {
      opacity: 0,
      duration: 5
    });*/
  },
  locateAnimation(animationName) {
    if (!window.wonderwp.FeatureDetector.has('motion')) {
      return 'FadeAnimation';
    }
    if (!animationName) {
      animationName = this.defaultAnimation;
    }
    let animationClass = '';
    try {
      animationClass = new animations[animationName]();
      animationClass.name = animationName;
    } catch (e) {
      console.error("Animation [" + animationName + '] not found in ajax/animations index', e);
      if (animationName !== 'FadeAnimation') {
        return this.locateAnimation('FadeAnimation');
      } else {
        return null;
      }
    }
    return animationClass;
  },
  log(msg) {
    if (this.debug) {
      console.log('[Ajax][Transition] ', ...arguments);
    }
  }
}
