import {PageUpdater} from "../Service/PageUpdater";

export default class AbstractAjaxManager {
  init() {
    this.debug = false;
    this.pageUpdater = new PageUpdater();
  }

  getSelectorExclusionRules() {
    return [
      '.no-transition',
      '.no-barba',
      '.module-actu .pagination a',
      '.module-faq .pagination a',
      '.post-edit-link',
      '.timeline-component a',
      '.wdf-modal',
      '.page_item_has_children > a'
    ];
  }

  getPageCacheExlusionRules() {
    return false;
  }

  getPrefetchExclusionRules() {
    return false;
  }

  beforeLeave($oldContainer) {
    this.log('-> Before Leave function');
    this.pageUpdater.setContainer($oldContainer);
    this.pageUpdater.closeMenu();
  }

  afterLeave($oldContainer) {
    this.log('-> After Leave function');
    this.pageUpdater.cleanUpJs();
  }

  beforeEnter($newContainer, fetchedHtmlMarkup) {
    this.log('-> Before Enter function');
    this.pageUpdater.resetScrollPosition();
    this.pageUpdater.setContainer($newContainer);
    this.pageUpdater.setFetchedHtml(fetchedHtmlMarkup);
    this.pageUpdater.updateBodyClasses();
    this.pageUpdater.updateMenu();
    this.pageUpdater.initJs();
    this.pageUpdater.updateInlineStyles();
  }

  afterEnter($newContainer) {
    this.log('-> After Enter function');
    this.pageUpdater.triggerPageResize();
    this.pageUpdater.triggerPageView($newContainer);
  }

  log(msg) {
    if (this.debug) {
      console.log('[Ajax][Manager] ', ...arguments);
    }
  }
}
