import {gsap} from "gsap";

export class FadeAnimation {

  getLeaveAnimation($oldContainer, passedOptions, transitionData) {
    let defaultOptions = {},
      options = $.extend(defaultOptions, passedOptions);
    const leaveTransition = gsap.timeline(options);

    leaveTransition.to($oldContainer, {
      opacity: 0,
      duration: .5
    });

    return leaveTransition;
  }

  getEnterAnimation($newContainer, passedOptions, transitionData) {
    let defaultOptions = {},
      options = $.extend(defaultOptions, passedOptions);

    const enterTransition = gsap.timeline(options);

    enterTransition.from($newContainer.find('.entry-title,.excerpt'), {
      autoAlpha: 0,
      yPercent:5,
      duration: .5,
      stagger: .3
    });

    return enterTransition;
  }

}
