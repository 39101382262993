import {gsap} from "gsap";
import {FadeAnimation} from "./FadeAnimation";

export class HomeDefaultAnimation extends FadeAnimation {

  getLeaveAnimation($oldContainer, passedOptions, transitionData) {
    const $trigger = transitionData && transitionData.trigger && $(transitionData.trigger);

    if (!$trigger) {
      return super.getLeaveAnimation($oldContainer, passedOptions, transitionData);
    }

    let defaultOptions = {},
      options = $.extend(defaultOptions, passedOptions);
    const leaveTransition = gsap.timeline(options);
    const $parentSection = $trigger.parents('.wp-block-column');
    const $siblings = $parentSection.siblings();
    const animationDuration = 1.2;
    const animationEase = "expo.inOut";

    $parentSection.parent().addClass('transitionning');

    $parentSection.addClass('animating');
    leaveTransition
      .to($parentSection, {
        flexBasis: '100%',
        duration: animationDuration,
        ease: animationEase
      })
      .to($siblings, {
        flexBasis: '0%',
        duration: animationDuration,
        ease: animationEase,
      },"<");


    return leaveTransition;
  }
}
