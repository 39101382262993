import NoAjaxTransitionComponent from "../../NoAjaxTransition/NoAjaxTransitionComponent";

export class PageUpdater {

  constructor() {
    this.$container = null;
    this.fetchedHtml = null;
    this.debug = false;
    this.EventManager = window.EventManager || $(document);
  }

  setContainer(value) {
    this.$container = value;
  }

  setFetchedHtml(value) {
    this.fetchedHtml = value;
  }

  updateBodyClasses() {
    this.log('-> Update body classes');
    let bodyClasses = this.fetchedHtml.match(/body\sclass=['|"]([^'|"]*)['|"]/)[1];
    $('body').removeClass().addClass(bodyClasses);
  }

  updateMenu() {
    const pId = this.$container.attr('id').split('-')[1];
    this.log('-> Update menu with new ID ', pId);
    this.EventManager.trigger('PageUpdate.menu.update', {
      newId: pId,
    });
  }

  closeMenu() {
    this.log('-> Close menu');
    this.EventManager.trigger('PageUpdate.menu.close', {});
  }

  resetScrollPosition() {
    window.scrollTo(0, 0);
  }

  initJs() {
    this.log('-> Running pew in new container');
    this.EventManager.trigger('PageUpdate.JS.initComponents', {
      container: this.$container
    });
    window.pew.enhanceRegistry(this.$container[0]);
    new NoAjaxTransitionComponent(this.$container);
  }

  cleanUpJs() {
    this.log('-> Destroying pew instances in old container');
    //destroy pew instances in old container
    this.EventManager.trigger('PageUpdate.JS.deleteComponents', {
      container: this.$container
    });
    this.$container.find('[data-pew-element]').each((i, elt) => {
      $(elt).trigger('destroy');
    });
  }

  updateInlineStyles() {
    const inlineStyles = this.fetchedHtml.match(/<style>.wp-container(.*?)<\/style>/g).join("\n");
    $('#jsTemplates ~ style').remove();
    $('#jsTemplates').after(inlineStyles);
  }

  triggerPageView() {
    this.log('-> Trigger page view');
    this.EventManager.trigger('Tracking.pageEvent', {
      page: window.location.pathname,
      type: 'view'
    });
  }

  triggerPageResize() {
    window.dispatchEvent(new Event('resize'));
  }

  log(msg) {
    if (this.debug) {
      console.log('[Ajax][Page Updater] ', ...arguments);
    }
  }
}
