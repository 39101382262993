import {gsap} from "gsap";

export class DefaultWdfPageAnimation {

  getLeaveAnimation($oldContainer, passedOptions, transitionData) {
    let defaultOptions = {},
      options = $.extend(defaultOptions, passedOptions);
    const leaveTransition = gsap.timeline(options);
    /*,
      $visibleElements = this.getVisibleElements($oldContainer);

    leaveTransition.to($visibleElements, {
      opacity: 0,
      y: -10,
      stagger: 0.1,
      duration: .3,
    });*/

    leaveTransition.to($oldContainer.find('.entry-header'), {
      autoAlpha: 0,
      x: 50,
      duration: .2
    });
    leaveTransition.to($oldContainer, {
      autoAlpha: 0,
      x: 50,
      duration: .2
    }, .12);

    return leaveTransition;
  }

  getEnterAnimation($newContainer, passedOptions, transitionData) {
    let defaultOptions = {},
      options = $.extend(defaultOptions, passedOptions);

    const enterTransition = gsap.timeline(options);
    const $entryContent = $newContainer.find('.entry-content');
    /*,
      $visibleElements = this.getVisibleElements($newContainer);

    enterTransition.from($visibleElements, {
      opacity: 0,
      y: 10,
      stagger: 0.1,
      duration: .3
    });*/

      enterTransition.fromTo($newContainer.find('.entry-header'), {
      duration: .3,
      autoAlpha: 0,
      x: -50,
    }, {
      autoAlpha: 1,
      x: 0,
    });
    enterTransition.fromTo($entryContent, {
        duration: .3,
        autoAlpha: 0,
        x: -50,
      }, {
        autoAlpha: 1,
        x: 0
      }, .12
    );

    return enterTransition;
  }

  getVisibleElements($container) {
    const $elements = $container.find(':header, li, img, p').filter(':visible');
    let $visibleElements = [];

    const bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
    const top_of_screen = $(window).scrollTop();

    $elements.each((i, elt) => {
      let $elt = $(elt);
      var top_of_element = $elt.offset().top;
      var bottom_of_element = $elt.offset().top + $elt.outerHeight();
      if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
        // the element is visible, do something
        $visibleElements.push($elt);
      }

    });
    return $visibleElements;
  }

}
