const svg4Everybody = require('svg4everybody');
import BarbaAjaxManager from "../../../includes/Components/Ajax/Managers/BarbaAjaxManager";

class App {
  init() {
    this.launchPew();
    this.launchSvg4Everybody();
    this.initAjax();
  }

  launchPew() {
    if (window.pew) { window.pew.enhanceRegistry(document); }
  }

  launchSvg4Everybody() {
    svg4Everybody();
  }

  initAjax() {
    if (window.wonderwp.FeatureDetector.has('promise')) {
      const ajaxComponent = new BarbaAjaxManager();
      ajaxComponent.init();
    }
  }
}

let app = new App();
app.init();
