const barba = require('Barba');
import AbstractAjaxManager from "./AbstractAjaxManager";
import {DefaultTransition} from "../Transitions/DefaultTransition";

export default class BarbaAjaxManager extends AbstractAjaxManager {

  init() {
    super.init();
    this.initBarba();
    this.initBarbaHooks();
  }

  initBarba() {
    let barbaOptions = {
      timeout: 8000,
      prevent: (event) => {
        const $el = $(event.el);
        const exceptions = this.getSelectorExclusionRules();
        return $el.is(exceptions.join(','));
      },
      cacheIgnore: this.getPageCacheExlusionRules(),
      prefetchIgnore: this.getPrefetchExclusionRules(),
      debug: false
    };

    barbaOptions.transitions = [DefaultTransition];
    this.log('Initing barba with options', barbaOptions);
    barba.init(barbaOptions);
  }

  initBarbaHooks() {
    this.log('Initing barba hooks');

    //Before leave transition/view
    barba.hooks.beforeLeave((data) => {
      this.log('[Before Leave Hook]');
      data.trigger.blur();
      this.beforeLeave($(data.current.container));
    });

    //After leave transition/view (next container is DOM ready)
    barba.hooks.afterLeave((data) => {
      this.log('[After Leave Hook]');
      this.afterLeave($(data.current.container));
    });

    //Before enter transition/view
    barba.hooks.beforeEnter((data) => {
      this.log('[Before Enter Hook]');
      //console.log(data);
      this.beforeEnter($(data.next.container), data.next.html);
    });

    //After enter transition/view
    barba.hooks.afterEnter((data) => {
      this.log('[After Enter Hook]');
      if (data.trigger !== 'barba') {
        this.afterEnter($(data.next.container));
      }
    });

  }
}
